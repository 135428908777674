import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import VerticalSectionTitle from "./verticalSectionTitle"

const SectionInfo = ({
  headingTag: HeadingTag = "p",
  sectionHeading,
  sectionHeadingMobile,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ascendCrowd: file(
            relativePath: { eq: "20160909-143403-Ascend2016-Lefferts 2.png" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <StyledSection>
            <div className="grid-container">
              <div className="grid-x grid-padding-x">
                <StyledVerticalSectionTitle>
                  <VerticalSectionTitle
                    svg={sectionHeading}
                    mobileSvg={sectionHeadingMobile}
                  />
                </StyledVerticalSectionTitle>
                <LeftColumn className="double-padding-left">
                  <ListHeading>Cancellation Policy</ListHeading>
                  <InfoP>
                    Ascend attendees can cancel via written notice to{" "}
                    <a href="mailto:events@webpt.com">events@webpt.com</a> up to
                    three weeks before the event date for a full refund. Any
                    attendee who cancels after August 22, 2024 will forfeit all
                    registration fees. WebPT may cancel Ascend due to unforeseen
                    circumstances; however, the event planners will take all
                    measures to ensure the event goes on as planned. Should the
                    event be rescheduled, registrants will have the option to
                    receive a full refund or tickets to the rescheduled event.
                  </InfoP>
                  <ListHeading>Ticket Transfer Policy</ListHeading>
                  <InfoP>
                    In the event that someone cannot attend Ascend, he or she
                    may transfer his or her ticket to another person who would
                    like to attend. Individuals who would like to transfer a
                    ticket must contact Ascend organizers at events@webpt.com
                    before August 22, 2024.
                  </InfoP>
                  <ListHeading>Questions?</ListHeading>
                  <p>
                    Contact us at{" "}
                    <a href="mailto:events@webpt.com">events@webpt.com</a>.
                  </p>
                </LeftColumn>
                <div className="small-12 medium-5 cell medium-offset-1">
                  <ListHeading>Attendance Prohibitions</ListHeading>
                  <InfoP>
                    Ascend is for rehab therapy professionals who want to learn
                    about industry trends and network with colleagues.
                    Competitors of WebPT and people attending on behalf of
                    competitors (collectively “Competitors”) are prohibited from
                    attending Ascend. In our sole discretion, we will not accept
                    Ascend registrations that we believe are Competitors.
                    Competitors discovered at Ascend will be removed
                    immediately.
                  </InfoP>
                  <InfoP>
                    All materials about WebPT disseminated or produced at
                    Ascend, including photos and videos of Ascend, are WebPT
                    proprietary information and may not be shared without our
                    express written consent. If we discover WebPT proprietary
                    information has been shared without our permission, we will
                    pursue legal remedies to enforce our rights.
                  </InfoP>
                </div>
              </div>
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionInfo

const StyledSection = styled.section`
  padding: 100px 0 50px;

  .grid-padding-x > .cell.double-padding-left {
    @media print, screen and (min-width: 40em) {
      padding-left: 1.875rem;
    }
    @media screen and (max-width: 1024px) {
      padding-left: 0.9375rem;
    }
    @media screen and (max-width: 640px) {
      padding-left: 0.9375rem;
    }
  }
`
const StyledVerticalSectionTitle = styled.div``
const ListHeading = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
`
const InfoP = styled.p`
  margin-bottom: 30px;
`
const LeftColumn = styled.div.attrs({
  className: "small-12 medium-5 cell",
})`
  @media print, screen and (min-width: 40em) {
    padding-left: 1.875rem;
  }

  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 640px) {
  }
`
