import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import SectionMediaHalf from "./mediaHalf"

const SectionSale = ({
  headingTag: HeadingTag = "p",
  ctaText,
  ctaLink,
  anchorLinkId,
}) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          ascendCrowd: file(relativePath: { eq: "LearnNetworkGrow.jpg" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const ascendCrowd = data.ascendCrowd.childImageSharp.fluid
        return (
          <StyledSection>
            <div className="grid-container">
              <SectionMediaHalf
                image={ascendCrowd}
                videoUrl="https://share.vidyard.com/watch/fF1XLip5fK2qvGhFmoUCR5"
                showVideo={false}
                sectionHeading=""
                mediumOrder={2}
                smallOrder={1}
                textSmallOrder={1}
                textMediumOrder={1}
                textLargeOrder={1}
                textSmallOffset={0}
                textMediumOffset={0}
                textLargeOffset={0}
                textWidthMedium={5}
                imageWidthMedium={5}
                mediaSmallOffset={0}
                mediaMediumOffset={1}
                mediaLargeOffset={2}
                mediaSmallOrder={2}
                mediaMediumOrder={2}
                mediaLargeOrder={2}
                headingTag="h3"
                sectionBody="<h3>Learn, Network, and Grow at the Ultimate Rehab Therapy
                    Business Summit</h3>
                    <p>
                    <strong>
                      What does this ticket include?
                    </strong>
                  </p>
                  <ul>
                  <li>
                    Two days of inspiring educational content—delivered by
                    rehab’s most influential thought leaders
                  </li>
                  <li>Access to an assortment of CEUs</li>
                  <li>
                    Multiple networking opportunities to share ideas and
                    collaborate with your peers
                  </li>
                  <li>World-class welcome reception and happy hour events</li>
                  <li>A balanced breakfast and lunch every day</li>
                  <li>
                    Full access to sponsors that will share the latest and
                    greatest in rehab therapy technology and services
                  </li>
                  <li>
                    A boatload of Ascend and sponsor swag (maybe pack an extra
                    suitcase?)
                  </li>
                  </ul>"
                ctaText={ctaText}
                ctaLink={ctaLink}
                anchorLinkId={anchorLinkId}
              />
            </div>
          </StyledSection>
        )
      }}
    />
  )
}

export default SectionSale

const StyledSection = styled.section`
  padding: 100px 0 50px;
  @media screen and (max-width: 1024px) {
  }
  @media screen and (max-width: 640px) {
    padding: 30px 0 50px;
  }
`
