import React from "react"
import styled from "styled-components"
import { Script } from "gatsby"

const SectionWidgetRegistration = ({
  eventId,
  sectionTitle,
  headingTag: HeadingTag = "p",
}) => (
  <StyledWidgetRegistration>
    <Script id="bizzabo-widget-id">{`  
 (function(){var bz=document.createElement("script");bz.type="text/javascript";bz.async=true;bz.src="https://organizer.bizzabo.com/widgets/tickets/tickets.js";var s=document.getElementsByTagName("script")[0];s.parentNode.insertBefore(bz,s);})();
  `}</Script>
    <div className="grid-container" id="register-for-ascend">
      <div className="grid-x grid-padding-x">
        <div className="small-12 cell text-center">
          <HeadingTag className="section-title">{sectionTitle}</HeadingTag>
        </div>
        <div className="small-12 large-10 large-offset-1 cell">
          <StyledWidgetContainer
            className="bizzabo-tickets-widget"
            data-event-id={eventId}
            data-params="isInMinisite=true&widgetId=60121"
          />
        </div>
      </div>
    </div>
  </StyledWidgetRegistration>
)

export default SectionWidgetRegistration
const StyledWidgetRegistration = styled.section`
  .section-title {
    margin-bottom: 20px;
  }
`
const StyledWidgetContainer = styled.div`
  min-height: 400px;
  @media screen and (max-width: 640px) {
    min-height: 400px;
  }
`
