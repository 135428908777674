import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SectionHeroPage from "../../components/sections/heroPage"
import HeadingTickets from "../../images/svg/inline-svg/heading_tickets.svg"
import HeadingSale from "../../images/svg/inline-svg/heading_sale.svg"
import HeadingSaleMobile from "../../images/svg/inline-svg/heading_sale_mobile.svg"
import HeadingInfo from "../../images/svg/inline-svg/heading_info.svg"
import HeadingInfoMobile from "../../images/svg/inline-svg/heading_info_mobile.svg"
import SectionSale from "../../components/sections/sectionSale"
import SectionInfo from "../../components/sections/sectionInfo"
import HeadingTicketsMobile from "../../images/svg/inline-svg/heading_tickets_mobile.svg"
import SectionWidgetRegistration from "../../components/sections/widgetRegistration"
import SectionCurrentSponsors from "../../components/sections/sectionSponsorCurrent"
import HeadingPastSpnsrs from "../../images/svg/inline-svg/heading_past-spnsrs.svg"
import HeadingPastSpnsrsMobile from "../../images/svg/inline-svg/heading_past-spnsrs_mobile.svg"

const PricingPage = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              bizzaboEventId
              year
              bizzaboEventId
            }
          }
          headerImageMobile: file(
            relativePath: { eq: "headers/hero-tickets-mobile.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 624) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          headerTickets: file(
            relativePath: { eq: "headers/hero-tickets.jpg" }
          ) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        const headerTickets = data.headerTickets.childImageSharp.fluid
        const headerImageMobile = data.headerImageMobile.childImageSharp.fluid
        const year = data.site.siteMetadata.year
        const eventId = data.site.siteMetadata.bizzaboEventId

        return (
          <Layout location={location}>
            <Seo
              title={`Ascend ${year} Tickets`}
              location={`https://ascendevent.com${location.pathname}`}
              description="Find more information on what prices to attend this years best Physical Therapists and Business conference"
            />
            <SectionHeroPage
              eyebrow=""
              heading="Get Your Tickets for Ascend 2024"
              showHeadingForScreenReaderOnly={true}
              subheading="We Can't Wait to Welcome You to Ascend"
              image={headerTickets}
              mediaMediumOffset={0}
              textWidth={4}
              sectionHeading={<HeadingTickets />}
              anchorLinkId="#register-for-ascend"
              mobileSectionHeading={<HeadingTicketsMobile />}
              mobileImage={headerImageMobile}
            />
            <SectionSale
              sectionHeading={<HeadingSale />}
              sectionHeadingMobile={<HeadingSaleMobile />}
              ctaText={"Get Your Tickets"}
              ctaLink=""
              anchorLinkId="#register-for-ascend"
            />
            <SectionWidgetRegistration
              eventId={eventId}
              sectionTitle="Register Now"
              headingTag="h3"
            />
            <SectionInfo
              sectionHeading={<HeadingInfo />}
              sectionHeadingMobile={<HeadingInfoMobile />}
            />
            <SectionCurrentSponsors
              sectionHeading={<HeadingPastSpnsrs />}
              sectionHeadingMobile={<HeadingPastSpnsrsMobile />}
            />
          </Layout>
        )
      }}
    />
  )
}

export default PricingPage
